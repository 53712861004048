import {faHome, faToolbox, faUser} from "@fortawesome/free-solid-svg-icons"

export default [
    {
        text: "Projects",
        icon: faToolbox,
        link: "/projects"
    },
    {
        text: "About Me",
        icon: faUser,
        link: "/about"
    },

]