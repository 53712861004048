import NavBar from "./NavBar/NavBar"
import React from "react"
import { Helmet } from "react-helmet"
import { makeStyles } from "@material-ui/core"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "./theme"
import { config, dom } from '@fortawesome/fontawesome-svg-core'
import "../../styles/global.css"
import Footer from "./Footer/Footer";

// For font awesome flicker 
config.autoAddCss = false;

export default function Layout({ children }) {
    return (
        <>
            <Helmet>
                <title>Matthew Hoare</title>
                <style type="text/css">{dom.css()}</style>
            </Helmet>

            <ThemeProvider theme={theme}>
                <NavBar />
                {children}
                {/* <Footer /> */}
            </ThemeProvider>
        </>
    )
}