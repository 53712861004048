import React, { Component } from "react"
import AppBar from "@material-ui/core/AppBar"
import { Drawer, Toolbar } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import withStyles from "@material-ui/core/styles/withStyles"
import NavLinks from "./NavLinks"
import DesktopNavButtons from "./DesktopNavButtons";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import theme from "../theme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const styles = theme => ({
    appBar: {
        zIndex: 12001 // todo change
    },
    navTitle: {
        "& a": {
            font: "Arial Black",
            color: "#ffffff",
            textDecoration: "none",
        },
        marginRight: "16px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            textAlign: "center"
        },
    },
    menuButton: {
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    desktop_nav: {
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    desktop_nav_button: {
        color: "white",
        textDecoration: "none",
        ...theme.mixins.toolbar,
    },
});

export class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            drawer_open: false,
        }
    }

    handleDrawer = () => {
        this.setState({
            drawer_open: !this.state.drawer_open
        })
    };

    render() {
        const {classes} = this.props;
        console.log(theme);
        return (
            <>
                <AppBar position="sticky" className={classes.appBar}>
                    <Toolbar>

                        <IconButton
                            onClick={this.handleDrawer}
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu">
                            <MenuIcon />
                        </IconButton>

                        <Typography variant="h4" noWrap className={classes.navTitle}>
                            <Link to="/">Matthew Hoare</Link>
                        </Typography>


                        <div className={classes.desktop_nav}>
                            {NavLinks.map(l => {
                                return <DesktopNavButtons
                                    css={classes.desktop_nav_button}
                                    {...l}
                                />
                            })}
                        </div>

                    </Toolbar>
                </AppBar>

                <Drawer anchor="left" open={this.state.drawer_open} onClose={this.handleDrawer}>
                    <div style={{ marginTop: "64px", width: "50vw" }}>

                        <List component='nav'>
                            {NavLinks.map(l => {
                                return <Link to={l.link} style={{ textDecoration: "none", color: "black" }}>
                                    <ListItem button>
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={l.icon} />
                                        </ListItemIcon>
                                        <ListItemText primary={l.text} />
                                    </ListItem>
                                </Link>
                            })}
                        </List>

                    </div>
                </Drawer>

            </>
        )
    }
}

export default withStyles(styles)(NavBar)