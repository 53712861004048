import Button from "@material-ui/core/Button"
import {Link} from "gatsby"
import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    link_button: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
            textDecorationColor: "white"
        }
    }
});


export default function ({text, icon, link, css}) {
    const classes = useStyles();
    return (
        <Link to={link} className={classes.link_button}>
            <Button
                style={{marginRight: "8px"}}
                className={css}>
                <FontAwesomeIcon size="2x" icon={icon} style={{marginRight: "8px"}}/>
                <Typography style={{marginTop: "8px", textDecoration: "none"}}>
                    {text}
                </Typography>
            </Button>
        </Link>
    )
}