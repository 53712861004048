import createMuiTheme from "@material-ui/core/styles/createMuiTheme"
import {indigo, purple} from "@material-ui/core/colors"
import {responsiveFontSizes} from "@material-ui/core"

export default responsiveFontSizes(
    createMuiTheme({
        palette: {
            primary: indigo,
            secondary: purple,
        },
        status: {
            danger: "orange",
        },
    })
)

