import { faGithub, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

export default [
  {
    text: "/hoarematthew",
    icon: faLinkedinIn,
    link: "https://www.linkedin.com/in/hoarematthew/",
    label: "Linkedin"
  },
  {
    text: "matthoare013",
    icon: faGithub,
    link: "https://github.com/matthoare013",
    label: "Github"
  }, {
    text: "@matthoare013",
    icon: faTwitter,
    link: "https://twitter.com/matthoare013",
    label: "Twitter"
  }, {
    text: "matthoare013@gmail.com",
    icon: faEnvelope,
    link: "mailto:matthoare013@gmail.com",
    label: "Email"
  },
]
