import React from "react"
import AppBar from "@material-ui/core/AppBar"
import {makeStyles, Toolbar, Typography} from "@material-ui/core"
import theme from "../theme"
import Grid from "@material-ui/core/Grid"
import Social from "./Social"
import SocialButton from "./SocialButton"

const useStyles = makeStyles({
    footer: {
        zIndex: 12001,
        backgroundColor: "#303F9F",
        [theme.breakpoints.up("md")]: {},
    },
    contact: {
        borderRight: '1px solid white',
        paddingRight: "16px",
        marginRight: theme.spacing(2),
    },
});


export default function Footer() {
    const classes = useStyles();

    return (
        <AppBar position="relative" className={classes.footer}>
            <Toolbar>

                <Typography variant="h4" className={classes.contact}>
                    Connect
                </Typography>

                {/*{Social.map((s) => {*/}
                {/*    return <SocialButton icon={s.icon} text={s.text} to={s.link} label={s.label}/>*/}
                {/*})*/}
                {/*}*/}

            </Toolbar>
        </AppBar>
    )
}